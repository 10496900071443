import { SDK } from "@/types/aws";
import { SDKPack } from "@/types/download";
import { InternalGenerateSDKData } from "@/types/sdk";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface downloadState {
  downloadStatus: boolean;
  fileNameList: string[];
  downloadItemFileName: string;
  waitingStatus: boolean;
  downloadQuery: { [id: string]: SDK }[];
}

const initialState: downloadState = {
  downloadStatus: false,
  fileNameList: [],
  downloadItemFileName: "",
  waitingStatus: false,
  downloadQuery: [],
};

export const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    toggleDownload: (state, action: PayloadAction<boolean>) => {
      state.downloadStatus = action.payload;
    },
    addFileName: (state, action: PayloadAction<string>) => {
      const isAlreadyInside =
        state.fileNameList.findIndex(
          (file: string) => file === action.payload
        ) !== -1;

      if (!isAlreadyInside) {
        state.fileNameList.push(action.payload);
      }
    },
    removeFileName: (state, action: PayloadAction<string>) => {
      const indexToRemove = state.fileNameList.indexOf(action.payload);

      if (indexToRemove !== -1) {
        state.fileNameList.splice(indexToRemove, 1);
      }
    },
    resetFileName: (state) => {
      state.fileNameList = [];
    },
    toggleWaitingDialog: (state, action: PayloadAction<boolean>) => {
      state.waitingStatus = action.payload;
    },
    addDownloadQueryItem: (
      state,
      action: PayloadAction<{ [id: string]: SDK }>
    ) => {
      const payloadIndex = state.downloadQuery.findIndex(
        (item) => Object.keys(item)[0] === Object.keys(action.payload)[0]
      );
      if (payloadIndex !== -1) {
        state.downloadQuery.splice(payloadIndex, 1, action.payload);
      } else {
        state.downloadQuery.push(action.payload);
      }
    },
    removeDownloadQueryItem: (state, action: PayloadAction<string>) => {
      const payloadIndex = state.downloadQuery.findIndex((item) => {
        return Object.keys(item)[0] === action.payload;
      });
      if (payloadIndex !== -1) {
        state.downloadQuery.splice(payloadIndex, 1);
      }
    },
    setDownloadItemFileName: (state, action: PayloadAction<string>) => {
      state.downloadItemFileName = action.payload;
    },
    resetAllDownload: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleDownload,
  addFileName,
  removeFileName,
  resetFileName,
  toggleWaitingDialog,
  addDownloadQueryItem,
  removeDownloadQueryItem,
  setDownloadItemFileName,
  resetAllDownload,
} = downloadSlice.actions;

export default downloadSlice.reducer;

import { Feature, Software } from "@/types/software";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface softwareState {
  softwareSelectedCube1: Software[];
  softwareSelectedCube2: Feature[];
  buildPackages: boolean;
  activeTab: number;
  softwareList: Software[];
  featureList: Feature[];
}

const initialState: softwareState = {
  softwareSelectedCube1: [],
  softwareSelectedCube2: [],
  buildPackages: false,
  activeTab: 1,
  softwareList: [],
  featureList: [],
};

const containsSoftware = (obj: Software, list: Software[]) => {
  if (list) {
    return list.some((item) => item.softwareId === obj.softwareId);
  }
};

const containsFeature = (obj: Feature, list: Feature[]) => {
  if (list) {
    return list.some((item) => item.recipeId === obj.recipeId);
  }
};

export const softwareSlice = createSlice({
  name: "software",
  initialState,
  reducers: {
    addSoftware: (state, action: PayloadAction<Software[]>) => {
      action.payload.map((software) => {
        if (
          state.softwareSelectedCube1 &&
          !containsSoftware(software, state.softwareSelectedCube1)
        ) {
          state.softwareSelectedCube1.push(software);
        }
      });
    },
    addFeature: (state, action: PayloadAction<Feature[]>) => {
      action.payload.map((feature) => {
        if (
          state.softwareSelectedCube2 &&
          !containsFeature(feature, state.softwareSelectedCube2)
        ) {
          state.softwareSelectedCube2.push(feature);
        }
      });
    },
    toggleSoftware: (state, action: PayloadAction<Software>) => {
      const existingIndex = state.softwareSelectedCube1.findIndex(
        (item) => item.softwareId === action.payload.softwareId
      );

      if (existingIndex !== -1) {
        state.softwareSelectedCube1[existingIndex].isChecked = false;
        state.softwareSelectedCube1.splice(existingIndex, 1);
      } else {
        state.softwareSelectedCube1.push({
          ...action.payload,
          isChecked: true,
        });
      }
    },
    toggleFeature: (state, action: PayloadAction<Feature>) => {
      const existingIndex = state.softwareSelectedCube2.findIndex(
        (item) => item.recipeId === action.payload.recipeId
      );

      if (existingIndex !== -1) {
        state.softwareSelectedCube2[existingIndex].isChecked = false;
        state.softwareSelectedCube2.splice(existingIndex, 1);
      } else {
        state.softwareSelectedCube2.push({
          ...action.payload,
          isChecked: true,
        });
      }
    },
    resetTab: (state) => {
      const includeItemCube1 =
        state.softwareSelectedCube1 &&
        state.softwareSelectedCube1.filter((item) => item.suggested === true);

      state.softwareSelectedCube1 = includeItemCube1;
      state.softwareSelectedCube2 = [];
    },
    toggleBuild: (state) => {
      state.buildPackages = !state.buildPackages;
    },
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setSoftwareList: (state, action: PayloadAction<Software[]>) => {
      state.softwareList = action.payload;
    },
    setFeatureList: (state, action: PayloadAction<Feature[]>) => {
      state.featureList = action.payload;
    },
    resetAllSoftware: () => initialState,
  },
});

export const {
  addSoftware,
  addFeature,
  toggleSoftware,
  toggleFeature,
  resetTab,
  toggleBuild,
  setActiveTab,
  setSoftwareList,
  setFeatureList,
  resetAllSoftware,
} = softwareSlice.actions;

export default softwareSlice.reducer;
